$('.team__slider .slider').slick({
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: true,
	variableWidth: true,
	prevArrow:"<button type='button' class='slick-prev'><svg width=\"53\" height=\"38\" viewBox=\"0 0 53 38\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
		"<path d=\"M51.7678 20.7678C52.7441 19.7915 52.7441 18.2085 51.7678 17.2322L35.8579 1.32233C34.8816 0.34602 33.2986 0.34602 32.3223 1.32233C31.346 2.29864 31.346 3.88155 32.3223 4.85786L46.4645 19L32.3223 33.1421C31.346 34.1184 31.346 35.7014 32.3223 36.6777C33.2986 37.654 34.8816 37.654 35.8579 36.6777L51.7678 20.7678ZM0 21.5H50V16.5H0V21.5Z\" fill=\"#0D32E8\"/>\n" +
		"</svg>\n</button>",
	nextArrow:"<button type='button' class='slick-next'><svg width=\"53\" height=\"38\" viewBox=\"0 0 53 38\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
		"<path d=\"M51.7678 20.7678C52.7441 19.7915 52.7441 18.2085 51.7678 17.2322L35.8579 1.32233C34.8816 0.34602 33.2986 0.34602 32.3223 1.32233C31.346 2.29864 31.346 3.88155 32.3223 4.85786L46.4645 19L32.3223 33.1421C31.346 34.1184 31.346 35.7014 32.3223 36.6777C33.2986 37.654 34.8816 37.654 35.8579 36.6777L51.7678 20.7678ZM0 21.5H50V16.5H0V21.5Z\" fill=\"#0D32E8\"/>\n" +
		"</svg>\n</button>",
});
