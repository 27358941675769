import '@babel/polyfill';
import svg4everybody from 'svg4everybody';
import $ from 'jquery';
import 'slick-carousel';
import 'gsap';

svg4everybody();

window.$ = $;
window.jQuery = $;

require('ninelines-ua-parser');
require('./vendor/fullpage.js');
require('./vendor/TweenLite.min');
require('./vendor/EasePack.min');
require('./vendor/demo');
