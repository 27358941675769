$('document').ready(function () {
	let trigger = $('#hamburger'),
		isClosed = true;

	trigger.click(function () {
		burgerTime();
	});

	function burgerTime() {
		if (isClosed == true) {

			trigger.removeClass('is-closed');

			trigger.addClass('is-open');
			isClosed = false;
			$('html').addClass('menu-open');
			$.fn.fullpage.setMouseWheelScrolling(false);
			$.fn.fullpage.setAllowScrolling(false);

		} else {
			$('html').removeClass('menu-open');
			trigger.removeClass('is-open');
			trigger.addClass('is-closed');
			isClosed = true;
			$.fn.fullpage.setMouseWheelScrolling(true);
			$.fn.fullpage.setAllowScrolling(true);
		}
	}
});
