import AOS from 'aos';
// import 'aos/dist/aos.css';

aosInit();

function aosInit() {
	AOS.init({
		once: true,
	});

	AOS.refresh({});
}

let loader = $('.loader-bg');

$(window).on('load', () => {
	$(document).ready(function(){
		$(this).scrollTop(0);
	});
	loader.fadeOut('fast','swing');

	TweenMax.from(".header", 2, {
		delay: 0.5,
		opacity: 0,
		y: -40,
		ease: Expo.ease
	});

	TweenMax.from(".intro__title", 2, {
		delay: 1.5,
		opacity: 0,
		x: -40,
		ease: Expo.ease
	});

	TweenMax.from(".intro__title span", 2, {
		delay: 1,
		opacity: 0,
		x: -40,
		ease: Expo.ease
	});

	TweenMax.from(".intro__subtitle", 2, {
		delay: 2,
		opacity: 0,
		x: -40,
		ease: Expo.ease
	});

	TweenMax.from(".intro__ssubtitle", 2, {
		delay: 2.3,
		opacity: 0,
		x: -40,
		ease: Expo.ease
	});

	TweenMax.from(".intro__description", 2, {
		delay: 2.6,
		opacity: 0,
		x: -40,
		ease: Expo.ease
	});

	TweenMax.from(".intro__text", 2, {
		delay: 2.9,
		opacity: 0,
		x: -40,
		ease: Expo.ease
	});

	TweenMax.from(".modal-open", 2, {
		delay: 3.2,
		opacity: 0,
		y: -40,
		ease: Expo.ease
	});

	TweenMax.from(".top--another .top__arrow", 2, {
		delay: 1.2,
		opacity: 0,
		x: -40,
		ease: Expo.ease
	});

	TweenMax.from(".top--another .top__title", 2, {
		delay: 1.4,
		opacity: 0,
		x: -40,
		ease: Expo.ease
	});

	TweenMax.from(".top--another .top__dubletitle", 2, {
		delay: 1.6,
		opacity: 0,
		x: -40,
		ease: Expo.ease
	});

	TweenMax.from(" .breadcrumbs", 2, {
		delay: 1.8,
		opacity: 0,
		x: -40,
		ease: Expo.ease
	});

	TweenMax.from(".project", 2, {
		delay: 2,
		opacity: 0,
		y: 40,
		ease: Expo.ease
	});
	TweenMax.from(".info__section--1", 2, {
		delay: 2,
		opacity: 0,
		y: 40,
		ease: Expo.ease
	});

	TweenMax.from(".info__section--2", 2, {
		delay: 2,
		opacity: 0,
		y: 40,
		ease: Expo.ease
	});
});


