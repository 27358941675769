let modalClose = $('.popup__close');
let modal = $('.popup');
let modalOpen = $('.modal-open');
let html = $('html');
let navSection = $('.fp-nav');

modalClose.on('click', (e) => {
	html.removeClass('modal-open');
	// modal.fadeOut();
	$.fn.fullpage.setMouseWheelScrolling(true);
	$.fn.fullpage.setAllowScrolling(true);
});

modalOpen.on('click', (e) => {
	html.addClass('modal-open');
	// modal.fadeOut();
	$.fn.fullpage.setMouseWheelScrolling(false);
	$.fn.fullpage.setAllowScrolling(false);
});
