$(document).ready(function () {
	function sectionScroll () {
		if ($(window).width() > 1024) {
			$('.main-section').fullpage({
				navigation: true,
				verticalCentered: true,
				licenseKey: 'OPEN-SOURCE-GPLV3-LICENSE',
				navigationTooltips: ['01', '02', '03', '04', '05', '06'],
				navigationPosition: 'left',
				anchors: ['firstSection', 'secondSection', 'thirdSection', 'fourthSection', 'fifthSection', 'SixSection'],

				afterLoad: function () {
					$('.fp-table.active .aos-init').addClass('aos-animate');
				}
			});
		}
	}

	sectionScroll();

	$(window).resize(() => {
		sectionScroll();
	});

	$('.main-section .up').on('click', () => {
		if($(window).width() > 1024) {
			$.fn.fullpage.moveTo(1, 0);
		}
	});

	$('.up').on('click', () => {
		$("html, body").animate({scrollTop: 0}, 600);
	});
});
